// 获取访问设备摄像头和语音
export const getMediaStream = async (constraints) => {
    return navigator.mediaDevices.getUserMedia(constraints);
};

// 获取视频音频设备
export const getDevices = async () => {
    const devices = {
        audio: [],
        selectedAudio: null,
        video: [],
        selectedVideo: null,
        speaker: [],
        selectedSpeaker: null,
    };

    (await navigator.mediaDevices.enumerateDevices()).forEach((mediaDevice) => {
        // 该情况出现在设备未被授权的情况下
        if (mediaDevice.label === "") {
            return;
        }

        const device = {
            id: mediaDevice.deviceId,
            name: mediaDevice.label,
        };

        // 扬声器
        if (mediaDevice.kind.toLowerCase().includes("audioinput")) {
            devices.audio.push(device);

            if (devices.selectedAudio === null) {
                devices.selectedAudio = device;
            }
        }

        // 麦克风
        if (mediaDevice.kind.toLowerCase().includes("audiooutput")) {
            devices.speaker.push(device);

            if (devices.selectedSpeaker === null) {
                devices.selectedSpeaker = device;
            }
        }

        // 摄像头
        if (mediaDevice.kind.toLowerCase().includes("video")) {
            devices.video.push(device);

            if (devices.selectedVideo === null) {
                devices.selectedVideo = device;
            }
        }
    });

    return devices;
};


// 创建本地流
export const createLocalStream = async ({audioDeviceId, videoDeviceId}) => {
    const audio = {
        deviceId: {exact: audioDeviceId},
        sampleRate: 44100,
        channelCount: {exact: 1},
        echoCancellation: true,
        noiseSuppression: true,
        autoGainControl: true,
    };
    const video = {
        deviceId: {exact: videoDeviceId},
        // facingMode: "user",
        width: {exact: 640},
        height: {exact: 360},
        frameRate: 15,
    };

    // const audio = audioDeviceId ? { deviceId: audioDeviceId } : true;
    // const video = videoDeviceId ? { deviceId: videoDeviceId } : true;

    try {
        // 尝试获取视频和音频
        return await getMediaStream({video, audio});
    } catch (err) {
        console.error(err);
        try {
            // 只尝试获取音频
            return await getMediaStream({audio});
        } catch (err) {
            console.error(err);
            try {
                // 只尝试获取视频
                return await getMediaStream({video});
            } catch (err) {
                console.error(err);
                // 都没有获取到
                return null;
            }
        }
    }
};

// 停止流
export const stopStream = (stream) => {
    if (stream === null) {
        return;
    }
    stream.getTracks().forEach((track) => {
        track.stop();
    });
};

// 获取流中摄像头和麦克风状态
export const getVideoAudioEnabled = (stream) => {
    if (!stream) {
        return { audioEnabled: false, videoEnabled: false };
    }

    const videoTracks = stream.getVideoTracks();
    const audioTracks = stream.getAudioTracks();

    const videoEnabled =
        videoTracks !== undefined &&
        videoTracks.length > 0 &&
        videoTracks[0].enabled;
    const audioEnabled =
        audioTracks !== undefined &&
        audioTracks.length > 0 &&
        audioTracks[0].enabled;

    return { audioEnabled, videoEnabled };
};
