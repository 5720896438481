import { createApp } from 'vue'
import App from './App.vue'
import store from "./store";
import router from "./router";
import ElementPlus from "element-plus"
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

import "reset.css";
import 'element-plus/dist/index.css'
import "@/assets/styles/index.scss"
import {DEV_API} from "@/utils";

const app = createApp(App)
// 定义全局变量
app.config.globalProperties.API_URL = `https://${process.env.NODE_ENV === "development" ? DEV_API : location.hostname}:443/api`
app.config.globalProperties.WS_URL = `wss://${process.env.NODE_ENV === "development" ? DEV_API : location.hostname}:443/groupcall`
app.config.globalProperties.USER_KEY = process.env.VUE_APP_USER_KEY
app.config.globalProperties.TOKEN_KEY = process.env.VUE_APP_TOKEN_KEY
// 赋值到 window
window.API_URL = app.config.globalProperties.API_URL
window.WS_URL = app.config.globalProperties.WS_URL
window.USER_KEY = process.env.VUE_APP_USER_KEY
window.TOKEN_KEY = process.env.VUE_APP_TOKEN_KEY

// 使用全局组件
app.use(store)
app.use(router)
app.use(ElementPlus, {locale: zhCn})

// 挂载 App
app.mount('#app')
