import {getVideoAudioEnabled} from "@/utils/stream";
import store from "@/store/index";

const getUser = () => {
    const localUser = localStorage.getItem(process.env.VUE_APP_USER_KEY)
    try {
        if (localUser) {
            return JSON.parse(localUser) || {}
        } else {
            return {}
        }
    } catch (e) {
        return {}
    }
}

export const Room = {
    state() {
        return {
            // websocket 对象
            ws: null,
            // 参会者
            participants: {},
            // 参会者设备状态
            deviceState: {},
            // 用户信息
            userData: getUser(),
            // 会议名称
            roomName: "",
            // 聊天窗口状态
            chatOpen: false,
            // 聊天记录
            chatRecord: [],
            // 聊天记录未读数量
            unread: 0,
            // 本地流
            localStream: null,
            // 屏幕共享对象
            screenShare: null
        };
    },

    mutations: {
        setSocket(state, ws) {
            state.ws = ws;
        },

        closeSocket(state) {
            if (state.ws) {
                state.ws.onclose = () => {};
                state.ws.close();
                state.ws = null
            }
        },

        sendMessage(state, message) {
            // 加入会议
            let jsonMessage = JSON.stringify(message);
            state.ws?.send(jsonMessage);
            // 默认赋值自己的设备状态
            state.deviceState[store.state.Room.userData.name] = getVideoAudioEnabled(store.state.Room.localStream)
        },

        disposeAll(state) {
            // 销毁所有参会者
            for (const key in state.participants) {
                state.participants[key]?.dispose();
                delete state.participants[key]
            }
            // 停止屏幕共享
            state.screenShare?.quit()
            state.screenShare = null
        },

        setRoomName(state, data) {
            state.roomName = data;
        },

        removeParticipant(state, name) {
            delete state.participants[name]
        },

        setParticipant(state, {name, participant}) {
            state.participants[name] = participant
            // 添加新用户的同时就添加状态, 防止界面循环的时候报错
            if (!state.deviceState[name]) {
                state.deviceState[name] = {}
            }
        },

        setDeviceState(state, {name, audioEnabled, videoEnabled}) {
            state.deviceState[name] = {audioEnabled, videoEnabled}
        },

        removeDeviceState(state, name) {
            delete state.deviceState[name]
        },

        clearDeviceState(state) {
            state.deviceState = {}
        },

        setScreenShare(state, data) {
            state.screenShare = data
        },

        SET_CHAT_RECORD(state, data) {
            state.chatRecord.push(data);
            // 未读 +1
            if (data.name !== state.userData.name && !state.chatOpen) {
                state.unread += 1
            }
        },

        SET_CHAT_OPEN(state, data) {
            state.chatOpen = data;
            state.unread = 0
        },

        SET_LOCAL_STREAM(state, data) {
            state.localStream = data;
        },

        SET_USER_DATA(state, data) {
            state.userData = data;
            localStorage.setItem(process.env.VUE_APP_USER_KEY, JSON.stringify(data))
        }
    },

    actions: {
        leaveRoom({commit}) {
            commit("sendMessage", {id: "leaveRoom"});
            commit("disposeAll");
            commit("closeSocket");
            commit("clearDeviceState");
        },

        joinRoom({commit, state}) {

            const message = {
                id: "joinRoom",
                name: state.userData.name,
                nickname: state.userData.nickname,
                room: state.roomName,
            };
            commit("sendMessage", message);
        },

        setChatRecord({commit}, payload) {
            commit("SET_CHAT_RECORD", payload);
        },

        setChatOpen({commit}, payload) {
            commit("SET_CHAT_OPEN", payload);
        },

        setLocalStream({commit}, payload) {
            commit("SET_LOCAL_STREAM", payload);
        },

        setUserData({commit}, payload) {
            commit("SET_USER_DATA", payload);
        }
    },
};
