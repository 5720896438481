import {createRouter, createWebHistory} from "vue-router";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            redirect: "/home"
        },
        {
            path: "/login",
            name: "Login",
            component: () => import("@/views/login.vue")
        },
        {
            path: "/home",
            name: "Home",
            component: () => import("@/views/home.vue")
        },
        {
            path: "/preview",
            name: "Preview",
            children: [
                {
                    path: "",
                    name: "PreviewRedirect",
                    redirect: "/home"
                },
                {
                    path: ":roomName",
                    name: "PreviewRoomName",
                    component: () => import("@/views/preview.vue")
                }
            ]
        },
        {
            path: "/meeting",
            name: "Meeting",
            children: [
                {
                    path: "",
                    name: "MeetingRedirect",
                    redirect: "/home"
                },
                {
                    path: ":roomName",
                    name: "MeetingRoomName",
                    component: () => import("@/views/meeting.vue")
                }
            ]
        },
        {
            path: "/users",
            name: "UserManage",
            component: () => import("@/views/userManage.vue")
        }
    ]
})

export default router;
