// 格式化时间格式
export function parseTime(time, pattern) {
    if (arguments.length === 0 || !time) {
        return parseTime(new Date());
    }

    const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}';
    let date;

    if (typeof time === 'object') {
        date = time;
    } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
            time = parseInt(time);
        } else if (typeof time === 'string') {
            time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000;
        }
        date = new Date(time);
    }

    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    };

    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key];

        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value];
        }

        if (result.length > 0 && Number(value) < 10) {
            value = '0' + value;
        }

        return value + "" || '0';
    });

    return time_str;
}

// 格式化文件类型
export function getFileType(filename) {
    if (!filename) {
        return "FILE.png"
    }

    const parts = filename.split('.');
    let fileExtension = parts[parts.length - 1]

    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
    const videoExtensions = ['mp4', 'avi', 'mkv', 'mov'];
    const archiveExtensions = ['zip', 'rar', 'tar', 'gz'];
    const wordExtensions = ['doc', 'docx'];
    const excelExtensions = ['xls', 'xlsx'];
    const pptExtensions = ['ppt', 'pptx'];
    const pdfExtensions = ['pdf'];

    fileExtension = fileExtension.toLowerCase();

    if (imageExtensions.includes(fileExtension)) {
        return 'IMAGE.png';
    } else if (videoExtensions.includes(fileExtension)) {
        return 'MP4.png';
    } else if (archiveExtensions.includes(fileExtension)) {
        return 'ZIP.png';
    } else if (wordExtensions.includes(fileExtension)) {
        return 'WORD.png';
    } else if (excelExtensions.includes(fileExtension)) {
        return 'EXCEL.png';
    } else if (pptExtensions.includes(fileExtension)) {
        return 'PPT.png';
    } else if (pdfExtensions.includes(fileExtension)) {
        return 'PDF.png';
    } else {
        return 'FILE.png';
    }
}

// 格式化文件大小
export function formatFileSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (bytes === 0) return '0 Byte';

    const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))));

    return Math.round(100 * (bytes / Math.pow(1024, i))) / 100 + ' ' + sizes[i];
}

// 格式化会议布局
export function calculateLayout(numUsers) {
    if (isMobile()) {
        return {rows: numUsers, cols: 1}
    }

    if (numUsers <= 0) {
        return {rows: 0, cols: 0};
    }

    let rows = 0;
    let cols = 0;

    if (numUsers <= 3) {
        rows = 1;
        cols = numUsers;
    } else if (numUsers <= 12) {
        rows = 2;
        cols = numUsers % 2 ? parseInt(numUsers / 2) + 1 : numUsers / 2;
    } else if (numUsers <= 20) {
        rows = 3;
        cols = parseInt(numUsers / 3) + (numUsers / 3 > 0 ? 1 : 0);
    }

    return {rows, cols};
}

// 封装 console.log 默认不允许打日志
export function myLog() {
    if (localStorage.getItem("LogEnable") === '1') {
        console.log(...[...arguments])
    }
}

// 设置日志开启关闭
window.setLog = (data) => {
    localStorage.setItem("LogEnable", data)
    const msg = `${data === 1 ? '\x1b[32m' : '\x1b[31m'}已${data === 1 ? '打开' : '关闭'}日志输出, ${data === 1 ? '关闭' : '打开'}方法: setLog(${Number(!data)})\x1b[0m`
    console.log(msg)
}

// 本地调试地址
export const DEV_API = "meet706.wzw.aa" // "39.101.70.6"

// 获取 ice servers 调试方法
window.getIceServers = () => {
    const defaultIceServers = [
        {
            urls: `turn:${process.env.NODE_ENV === "development" ? DEV_API : location.hostname}:3478?transport=tcp`,
            username: "admin",
            credential: "Beijing8"
        }
    ]
    const storeIceServers = localStorage.getItem("IceServers")
    if (storeIceServers) {
        try {
            return JSON.parse(storeIceServers)
        } catch (e) {
            return defaultIceServers
        }
    } else {
        return defaultIceServers
    }
}
// 删除 ice servers 调试方法
window.delIceServers = () => localStorage.removeItem("IceServers")
// 设置 ice servers 调试方法
window.setIceServers = (data) => {
    localStorage.setItem("IceServers", JSON.stringify(data))
    RtcPcOption.iceServers = data
}

// webrtc turn 参数
export const RtcPcOption = {
    iceServers: window.getIceServers(),
    iceTransportPolicy: "relay",
}

export function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
